/* eslint-disable @typescript-eslint/no-floating-promises */
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {CartType} from '@wix/wixstores-client-core/dist/es/src/types/cart';
import {
  thankYouPageLoadSf,
  thankYouPageContinueBrowsingLinkClickedSf,
  thankYouPageClickOnLinkOnLineItem,
} from '@wix/bi-logger-ec-site/v2';

export class BIService {
  private readonly siteStore: SiteStore;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
  }

  public thankYouPageContinueShoppingClickedSf({
    orderId,
    checkoutId,
    cartId,
    concatenatedCatalogAppIds,
  }: {
    orderId: string;
    checkoutId: string;
    cartId: string;
    concatenatedCatalogAppIds: string;
  }): void {
    void this.siteStore.webBiLogger.report(
      thankYouPageContinueBrowsingLinkClickedSf({
        orderId,
        cartId,
        checkoutId,
        catalogAppId: concatenatedCatalogAppIds,
      })
    );
  }

  public thankYouPageClickOnLinkOnLineItemSf({
    orderId,
    cartId,
    checkoutId,
    catalogItemId,
    itemType,
    actionName,
    concatenatedCatalogAppIds,
  }: {
    orderId: string;
    cartId: string;
    checkoutId: string;
    catalogItemId: string;
    itemType: string;
    actionName: string;
    concatenatedCatalogAppIds: string;
  }): void {
    void this.siteStore.webBiLogger.report(
      thankYouPageClickOnLinkOnLineItem({
        orderId,
        cartId,
        checkoutId,
        catalogItemId,
        itemType,
        actionName,
        catalogAppId: concatenatedCatalogAppIds,
      })
    );
  }

  public thankYouPageLoadSf({
    orderId,
    cartId,
    concatenatedCatalogAppIds,
    paymentMethodType,
    cartType,
    paymentStatus,
    paymentProvider,
    numOfLineItems,
    itemType,
    checkoutId,
    additionalFeesPrice,
    additionalFeesNumber,
  }: {
    orderId: string;
    cartId: string;
    concatenatedCatalogAppIds: string;
    shippingMethodType: string;
    paymentMethodType: string;
    cartType: CartType;
    paymentStatus: string;
    paymentProvider: string;
    numOfLineItems: number;
    itemType: string;
    checkoutId: string;
    additionalFeesPrice: number;
    additionalFeesNumber: number;
  }): void {
    let originForBI;
    const appSectionParams = this.siteStore.location.query.appSectionParams;
    if (appSectionParams) {
      originForBI = JSON.parse(appSectionParams).origin;
    }

    void this.siteStore.webBiLogger.report(
      thankYouPageLoadSf({
        orderId,
        cartId,
        cartType,
        paymentProvider,
        payment_status: paymentStatus,
        viewMode: this.siteStore.viewMode,
        origin: originForBI,
        numOfLineItems,
        itemType,
        checkoutId,
        paymentMethodType,
        additionalFeesPrice,
        numberOfAdditionalFees: additionalFeesNumber,
        catalogAppId: concatenatedCatalogAppIds,
      })
    );
  }
}
